import { Router } from "preact-router";
import { useEffect, useState } from "preact/hooks";
import Plausible from "plausible-tracker";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Information from "../routes/information";

import links from "../content/links.json";
import meta from "../content/meta.json";
import Action from "../routes/action";
import HumanRights from "../routes/human-rights";
import WorksCited from "../routes/works-cited";
import Quest from "../routes/quest";
import Greeting from "../routes/index";
import Header from "./Navigation/Header";
import QGF from "../routes/quest/google";
import NotFound from "../routes/404";
import Founder from "../routes/founder";

function Importer() {
  const classes = [
    "text-gray-100",
    "text-gray-400",
    "animate__animated",
    "animate__fadeInLeftBig",
    "animate__fadeInRightBig",
  ];
  return (
    <>
      <span aria-label="Color importer" className={classes.join(" ")} style={{ display: "none" }} />
      <span aria-label="Grid importer" className="col-span-1 col-span-2 col-span-3" />
    </>
  );
}

const getCurrentUrl = () => {
  if (typeof window !== "undefined") {
    return window.location.pathname
  }

  return "/"
}

function App() {
  const [page, setPage] = useState(getCurrentUrl());

  const plausible = Plausible({
    domain: "tas.zbrasher.us",
    apiHost: "https://a.funne.network",
  });

  useEffect(() => {
    setPage(getCurrentUrl());

    plausible.trackPageview();
  }, []);

  return (
    <div className="w-screen h-screen bg-zinc-900 justify-center overflow-x-auto" id="app">
      <div className="max-w-screen-lg mx-auto">
        {page !== "/" && (
        <Header
          meta={meta}
          links={links}
          page={page}
          setPage={setPage}
        />
        )}

        <main>
          <Router>
            <Greeting path="/" />
            <Home path="/home" />
            <Information path="/information" />
            <Action path="/action" />
            <Importer path="/aaaada" />
            <Founder path="/founder" />
            <WorksCited path="/works-cited" />
            <Quest path="/quest" />
            <QGF path="/quest/gf" />
            <HumanRights path="/human-rights" />
            <NotFound default />
          </Router>
        </main>
      </div>
    </div>
  );
}

export default App;
