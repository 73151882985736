import Prev from "../../components/Navigation/PreviousButton";

import quest from "../../content/quest.json";

function Quest() {
  return (
    <>
      <section className="text-white py-16">
        <div className="container mx-auto grid grid-rows-1 gap-4 w-full h-screen">
          <iframe
            title="Quest form"
            src={`https://docs.google.com/forms/d/e/${quest.form_id}/viewform?embedded=true`}
            width="1000"
            height="1000"
            // frameBorder="0"
            // marginHeight="0"
            // marginWidth="0"
          />
        </div>
      </section>
      <Prev pageIndicator="Information" href="/information" />
    </>
  );
}

export default Quest;
