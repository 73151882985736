import {
  MdPolicy,
} from "react-icons/md";

export default function Header({
  links, page, setPage,
}) {
  return (
    <header className="py-3 text-center">
      <h1 className="text-xl font-bold text-gray-50 flex justify-center items-center">
        <MdPolicy className="mr-2 p-2 w-fit h-fit rounded-full bg-gray-600" />
        Privacy: A right, not a privilege
      </h1>
      <h3 className="mt-2 text-l font-bold text-gray-50">A look at U.S. state surveillance of immigrants</h3>
      <hr className="mt-3 mb-3 bg-gray-300 " />
      <nav>
        {links.map(({ label, href }) => (
          <a
            href={href}
            className={`text-lg text-gray-${
              href === page ? "100" : "400"
            } hover:text-white mr-8 transition duration-300 ease-in-out`}
            onClick={() => setPage(href)}
            key={`link-${label.toLowerCase().replace(/[w]/g, "-")}`}
          >
            {label}
          </a>
        ))}
      </nav>
    </header>

  );
}
