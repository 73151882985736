import { MdArrowLeft } from "react-icons/md";

export default function Prev({
  pageIndicator = "",
  href = "/?href=none",
}) {
  return (
    <a href={href}>
      <button type="button" className="fixed bottom-2 left-2 bg-slate-500 hover:bg-slate-600 text-white font-bold px-4 py-2 rounded transition duration-300 ease-in-out focus:bg-slate-800 flex items-center">
        <MdArrowLeft className="mr-2" />
        Prev:
        {" "}
        {pageIndicator}
      </button>
    </a>
  );
}
